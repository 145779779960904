<mat-select [formControl]="control">
    @if (hint(); as hint) {
        <mat-option disabled class="hint-option">{{hint}}</mat-option>
    }
    <mat-select-trigger>
        @if (selectedBerth(); as berth) {
            <span>{{ berth.code }}</span>
            @if (berth.name) {
                &ngsp;<span class="text-de-emphasized text-sm">({{ berth.name }})</span>
            }
        }
    </mat-select-trigger>
    <mat-option [value]="null">-</mat-option>
    @for (berth of berths.value(); track berth.id) {
        <mat-option [value]="berth.id" class="h-[60px]">
            <div>{{ berth.code }}</div>
            @if (berth.name) {
                <div class="text-de-emphasized text-sm">{{ berth.name }}</div>
            }
        </mat-option>
    }
</mat-select>
